// IMPORTS
import "./styles/App.scss";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import Game from "./pages/Game";
// COMPONENT
const App = () => {
  // STATE
  const [state, setState] = useState({
    game: {
      started: false,
      over: false,
      paused: false,
      score: 0,
    },
    settings: {
      mobileControls:
        window.innerHeight <= 700 ||
        window.innerWidth <= 700 ||
        /iPad/i.test(navigator?.userAgentData?.platform),
    },
  });
  const {
    game: { started, over, paused, score },
    settings: { mobileControls },
  } = state;
  // RENDER
  return (
    <div className="appContainer">
      <Game mobileControls={mobileControls} setState={setState} />
      {over ? (
        <div className="menu">
          <p className="menuHeader">Game Over</p>
          <p className="menuText">You stacked {score} blocks</p>
          {!mobileControls && <p className="menuTitle">Press R to restart</p>}
        </div>
      ) : started ? (
        paused ? (
          <div className="menu">
            <p className="menuHeader">Paused</p>
            <p className="menuTitle">Press P to Play</p>
          </div>
        ) : (
          !mobileControls && (
            <div className="menu">
              <p className="menuTitle">Space = Set block</p>
              <p className="menuTitle">P = Puase</p>
            </div>
          )
        )
      ) : (
        <div className="menu">
          <p className="menuHeader">Stacker3D</p>
          <p className="menuText">
            - {mobileControls ? "Touch the screen" : "Press Space"} to set the
            block
          </p>
          <p className="menuText">- The game ends when you miss</p>
          <p className="menuTitle">
            {mobileControls ? "Touch anywhere" : "Press S"} to start
          </p>
        </div>
      )}
    </div>
  );
};
// INITIALIZE
ReactDOM.render(<App />, document.getElementById("root"));
