// IMPORTS
import React, { useCallback, useEffect, useRef } from "react";
import * as CANNON from "cannon";
import * as THREE from "three";
import { colors } from "../helpers/variables";
// COMPONENT
const Game = ({ mobileControls, setState }) => {
  // STATE
  const mount = useRef(null);
  // UPDATE STATE
  const updateState = useCallback(
    (gameState) => {
      // here
      setState((currentAppState) => {
        return {
          ...currentAppState,
          game: { ...currentAppState.game, ...gameState },
        };
      });
    },
    [setState]
  );
  // LIFE CYCLE
  useEffect(() => {
    // VARIABLES
    const aspect = window.innerWidth / window.innerHeight;
    const width = 10;
    const height = width / aspect;
    const boxHeight = 1;
    const originalBoxSize = 3;
    let robotPrecision = Math.random() * 1 - 0.5;
    let autopilot = true;
    let running = true;
    let paused = false;
    let score = 0;
    let lastTime = 0;
    let stack = [];
    let overhangs = [];
    let colorIndex = null;
    // WORLD
    const world = new CANNON.World();
    world.gravity.set(0, -10, 0);
    world.broadphase = new CANNON.NaiveBroadphase();
    world.solver.iterations = 40;
    // LIGHTS
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.6);
    const dirLight = new THREE.DirectionalLight(0xffffff, 0.6);
    dirLight.position.set(10, 20, 0);
    // CAMERA
    const camera = new THREE.OrthographicCamera(
      width / -2,
      width / 2,
      height / 2,
      height / -2,
      0,
      100
    );
    camera.position.set(4, 4, 4);
    camera.lookAt(0, 0, 0);
    // SCENE
    const scene = new THREE.Scene();
    scene.add(ambientLight);
    scene.add(dirLight);
    // RENDERER
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    mount.current.appendChild(renderer.domElement);
    // GENERATE BOX
    const generateBox = (x, y, z, width, depth, falls) => {
      const geometry = new THREE.BoxGeometry(width, boxHeight, depth);
      const material = new THREE.MeshLambertMaterial({
        color: stack.length === 0 ? 0x808080 : colors[colorIndex],
      });
      const mesh = new THREE.Mesh(geometry, material);
      mesh.position.set(x, y, z);
      scene.add(mesh);
      const shape = new CANNON.Box(
        new CANNON.Vec3(width / 2, boxHeight / 2, depth / 2)
      );
      let mass = falls ? 5 : 0;
      mass *= width / originalBoxSize;
      mass *= depth / originalBoxSize;
      const body = new CANNON.Body({ mass, shape });
      body.position.set(x, y, z);
      world.addBody(body);
      return {
        threejs: mesh,
        cannonjs: body,
        width,
        depth,
      };
    };
    // ADD LAYER
    const addLayer = (x, z, width, depth, direction) => {
      stack.length !== 0 && colorIndex === 10
        ? (colorIndex = 0)
        : (colorIndex += 1);
      const y = boxHeight * stack.length;
      const layer = generateBox(x, y, z, width, depth, false);
      layer.direction = direction;
      stack.push(layer);
    };
    // ADD OVERHANG
    const addOverhang = (x, z, width, depth) => {
      const y = boxHeight * (stack.length - 1);
      const overhang = generateBox(x, y, z, width, depth, true);
      overhangs.push(overhang);
    };
    // CUT BOX
    const cutBox = (topLayer, overlap, size, delta) => {
      const direction = topLayer.direction;
      const newWidth = direction === "x" ? overlap : topLayer.width;
      const newDepth = direction === "z" ? overlap : topLayer.depth;
      topLayer.width = newWidth;
      topLayer.depth = newDepth;
      topLayer.threejs.scale[direction] = overlap / size;
      topLayer.threejs.position[direction] -= delta / 2;
      topLayer.cannonjs.position[direction] -= delta / 2;
      const shape = new CANNON.Box(
        new CANNON.Vec3(newWidth / 2, boxHeight / 2, newDepth / 2)
      );
      topLayer.cannonjs.shapes = [];
      topLayer.cannonjs.addShape(shape);
    };
    // SET BLOCK
    const setBlock = () => {
      if (!running) return;
      const topLayer = stack[stack.length - 1];
      const previousLayer = stack[stack.length - 2];
      const direction = topLayer.direction;
      const size = direction === "x" ? topLayer.width : topLayer.depth;
      const delta =
        topLayer.threejs.position[direction] -
        previousLayer.threejs.position[direction];
      const overhangSize = Math.abs(delta);
      const overlap = size - overhangSize;
      if (overlap > 0) {
        cutBox(topLayer, overlap, size, delta);
        const overhangShift =
          (overlap / 2 + overhangSize / 2) * Math.sign(delta);
        const overhangX =
          direction === "x"
            ? topLayer.threejs.position.x + overhangShift
            : topLayer.threejs.position.x;
        const overhangZ =
          direction === "z"
            ? topLayer.threejs.position.z + overhangShift
            : topLayer.threejs.position.z;
        const overhangWidth = direction === "x" ? overhangSize : topLayer.width;
        const overhangDepth = direction === "z" ? overhangSize : topLayer.depth;
        addOverhang(overhangX, overhangZ, overhangWidth, overhangDepth);
        const nextX = direction === "x" ? topLayer.threejs.position.x : -10;
        const nextZ = direction === "z" ? topLayer.threejs.position.z : -10;
        const newWidth = topLayer.width;
        const newDepth = topLayer.depth;
        const nextDirection = direction === "x" ? "z" : "x";
        addLayer(nextX, nextZ, newWidth, newDepth, nextDirection);
        score += 1;
      } else {
        gameOver();
      }
    };
    // UPDATE PHYSICS
    const updatePhysics = (timePassed) => {
      world.step(timePassed / 1000);
      overhangs.forEach((element) => {
        element.threejs.position.copy(element.cannonjs.position);
        element.threejs.quaternion.copy(element.cannonjs.quaternion);
      });
    };
    // GAME OVER
    const gameOver = () => {
      const topLayer = stack[stack.length - 1];
      addOverhang(
        topLayer.threejs.position.x,
        topLayer.threejs.position.z,
        topLayer.width,
        topLayer.depth
      );
      world.remove(topLayer.cannonjs);
      scene.remove(topLayer.threejs);
      running = false;
      if (autopilot) {
        setTimeout(() => {
          startGame({ withAutopilot: true });
        }, 2000);
      } else {
        updateState({ over: true, score });
      }
    };
    // START GAME
    const startGame = ({ withAutopilot }) => {
      autopilot = withAutopilot;
      running = true;
      lastTime = 0;
      stack = [];
      overhangs = [];
      score = 0;
      if (world) {
        while (world.bodies.length > 0) {
          world.remove(world.bodies[0]);
        }
      }
      if (scene) {
        while (scene.children.find((child) => child.type === "Mesh")) {
          const mesh = scene.children.find((child) => child.type === "Mesh");
          scene.remove(mesh);
        }
        addLayer(0, 0, 10, 10);
        colorIndex = -1;
        addLayer(0, 0, originalBoxSize, originalBoxSize);
        addLayer(-10, 0, originalBoxSize, originalBoxSize, "x");
      }
      if (camera) {
        camera.position.set(4, 4, 4);
        camera.lookAt(0, 0, 0);
      }
    };
    // ADD MOBILE BUTTONS
    const addMobileButtons = () => {
      const restartButton = document.createElement("button");
      restartButton.className = "restartButton";
      restartButton.innerHTML = "Restart";
      restartButton.addEventListener("click", () => {
        running = true;
        startGame({ withAutopilot: false });
        updateState({ started: true, over: false });
      });
      mount.current.appendChild(restartButton);
    };
    // HANDLE EVENTS
    const handleEvents = () => {
      if (mobileControls) {
        window.addEventListener("touchstart", () => {
          if (autopilot) {
            startGame({ withAutopilot: false });
            updateState({ started: true, over: false });
            addMobileButtons();
          } else {
            setBlock();
          }
        });
      } else {
        window.addEventListener("keydown", (event) => {
          if (event.key === " ") {
            !autopilot && setBlock();
          }
          if (event.key === "R" || event.key === "r") {
            if (!running) {
              running = true;
              startGame({ withAutopilot: false });
              updateState({ started: true, over: false });
            }
          }
          if (event.key === "S" || event.key === "s") {
            if (autopilot) {
              startGame({ withAutopilot: false });
              updateState({ started: true, over: false });
            }
          }
          if (event.key === "P" || event.key === "p") {
            if (running & !autopilot) {
              if (paused) {
                renderer.setAnimationLoop(loop);
                paused = false;
                updateState({ paused });
              } else {
                renderer.setAnimationLoop((timestamp) => {
                  lastTime = timestamp;
                  return null;
                });
                paused = true;
                updateState({ paused });
              }
            }
          }
        });
      }
      const adjustCamera = () => {
        const aspect = window.innerWidth / window.innerHeight;
        const width = 10;
        const height = width / aspect;
        camera.top = height / 2;
        camera.bottom = height / -2;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.render(scene, camera);
      };
      window.addEventListener("resize", () => adjustCamera());
      window.addEventListener("orientationchange", () => adjustCamera());
    };
    // LOOP
    const loop = (timestamp) => {
      if (lastTime) {
        const timePassed = timestamp - lastTime;
        const speed = 0.008;
        const topLayer = stack[stack.length - 1];
        const previousLayer = stack[stack.length - 2];
        const tolerance = autopilot
          ? robotPrecision
          : topLayer.direction === "x"
          ? topLayer.width + 1
          : topLayer.depth + 1;
        const boxShouldMove =
          running &&
          topLayer.threejs.position[topLayer.direction] <
            previousLayer.threejs.position[topLayer.direction] + tolerance;
        if (boxShouldMove) {
          topLayer.threejs.position[topLayer.direction] += speed * timePassed;
          topLayer.cannonjs.position[topLayer.direction] += speed * timePassed;
          if (autopilot) {
            topLayer.threejs.position[topLayer.direction] > 10 && gameOver();
          } else {
            topLayer.threejs.position[topLayer.direction] > tolerance - 1 &&
              gameOver();
          }
        } else {
          if (autopilot) {
            setBlock();
            robotPrecision = Math.random() * 1 - 0.5;
          }
        }
        if (camera.position.y < boxHeight * (stack.length - 2) + 4) {
          camera.position.y += speed * timePassed;
        }
        updatePhysics(timePassed);
        renderer.render(scene, camera);
      }
      lastTime = timestamp;
    };
    // INITIALIZE
    handleEvents();
    startGame({ withAutopilot: true });
    renderer.setAnimationLoop(loop);
  }, [mobileControls, updateState]);
  // RENDER
  return <div ref={mount} />;
};
// EXPORT
export default Game;
